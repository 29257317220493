"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userMetadataSchema = void 0;
const yup = require("yup");
const eventDisplayConfigSchema = yup.object().shape({
    flight: yup
        .string()
        .oneOf(['EVENT_NAME', 'CALL_SIGN', 'MISSION_NUMBER', 'MISSION_PROFILE'])
        .notRequired()
        .default('EVENT_NAME'),
    tdy: yup.string().oneOf(['EVENT_NAME']).notRequired().default('EVENT_NAME'),
    ground: yup.string().oneOf(['MISSION_PROFILE']).notRequired().default('MISSION_PROFILE'),
    alertLine: yup.string().oneOf(['ALERT_LINE']).notRequired().default('ALERT_LINE'),
});
exports.userMetadataSchema = yup.object().shape({
    note: yup.string().notRequired(),
    firstLoginTime: yup.number().notRequired(),
    unreadNotificationsCount: yup.number().notRequired(),
    eventDisplayConfig: eventDisplayConfigSchema.notRequired().nullable(),
});
