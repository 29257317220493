import * as AuthActionTypes from '../actions/auth.actions';

import { createReducer, on } from '@ngrx/store';
import { User, UserMetadata } from 'contracts';

export const authStateFeatureKey = 'authState';

export interface IAuthState {
  isLoggedIn: boolean;
  user: User;
  canLoginWithBiometric: boolean;
}

export const authInitialState: IAuthState = {
  isLoggedIn: false,
  user: undefined,
  canLoginWithBiometric: false,
};

export const authReducer = createReducer(
  authInitialState,

  on(AuthActionTypes.SetAuthStatus, (state, action) => {
    return { ...state, isLoggedIn: action.isLoggedIn, user: action.user };
  }),

  on(AuthActionTypes.SetBiometricSecurityStatus, (state, action) => {
    return { ...state, canLoginWithBiometric: action.canLoginWithBiometric };
  }),

  on(AuthActionTypes.ResetAuthState, () => {
    return { ...authInitialState };
  }),

  on(AuthActionTypes.UpdateUnreadNotificationsCount, (state, action) => {
    const newMetadata: UserMetadata = new UserMetadata({
      ...state.user.metadata,
      unreadNotificationsCount: action.unreadNotificationsCount,
    });
    const authUser: User = new User({ ...state.user, metadata: new UserMetadata({ ...newMetadata }) });

    return { ...state, user: authUser };
  }),

  on(AuthActionTypes.UpdateAuthUser, (state, action) => {
    return { ...state, user: action.user };
  }),

  on(AuthActionTypes.UpdateEventDisplayConfig, (state, action) => {
    const newMetadata: UserMetadata = new UserMetadata({
      ...state.user.metadata,
      eventDisplayConfig: action.eventDisplayConfig,
    });
    const authUser: User = new User({ ...state.user, metadata: new UserMetadata({ ...newMetadata }) });

    return { ...state, user: authUser };
  })
);
