"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.proflytPayStatusSchema = void 0;
const yup = require("yup");
exports.proflytPayStatusSchema = yup.object().shape({
    id: yup.string().required(),
    firstHalfDay: yup.number().required(),
    secondHalfDay: yup.number().required(),
    firstHalfDayType: yup.string().required(),
    secondHalfDayType: yup.string().required(),
    squadronId: yup.string().required(),
    userId: yup.string().required(),
    date: yup.number().required(),
    relationTimeId: yup.string().required(),
});
