"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProflytPayStatus = void 0;
const proflyt_pay_status_schema_1 = require("./proflyt-pay-status.schema");
const base_model_1 = require("../base-model");
class ProflytPayStatus extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return proflyt_pay_status_schema_1.proflytPayStatusSchema;
    }
}
exports.ProflytPayStatus = ProflytPayStatus;
