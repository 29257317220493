"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
const enums_1 = require("../../enums");
const user_schema_1 = require("./user.schema");
const aircrew_type_1 = require("../aircrew-type");
const user_configuration_1 = require("./user-configuration");
const user_config_time_zone_enum_1 = require("../../enums/user-config-time-zone.enum");
const base_model_1 = require("../base-model");
class User extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        this.joinedAt = new Date().getTime();
        if (data === null || data === void 0 ? void 0 : data.role) {
            this.role = data.role;
        }
        if (data === null || data === void 0 ? void 0 : data.aircrewPosition) {
            this.aircrewPosition = new aircrew_type_1.AircrewType(data.aircrewPosition);
            this.sortByAircrewPositionType = this.aircrewPosition.type;
        }
        if (data === null || data === void 0 ? void 0 : data.invitedAt) {
            this.invitedAt = data.invitedAt;
        }
        this.enableApp = !!(data === null || data === void 0 ? void 0 : data.enableApp);
        this.mct = !!(data === null || data === void 0 ? void 0 : data.mct);
        this.nmr = !!(data === null || data === void 0 ? void 0 : data.nmr);
        if (!(data === null || data === void 0 ? void 0 : data.isPublicUser)) {
            this.isPublicUser = false;
        }
        if (!(data === null || data === void 0 ? void 0 : data.isRatingReply)) {
            this.isRatingReply = false;
        }
        if (!(data === null || data === void 0 ? void 0 : data.defaultTimezone)) {
            this.defaultTimezone = user_config_time_zone_enum_1.UserConfigTimeZoneType.Squadron;
        }
        this.devicesAcceptingPushNotifications = (data === null || data === void 0 ? void 0 : data.devicesAcceptingPushNotifications) || [];
        if (!(data === null || data === void 0 ? void 0 : data.notificationConfiguration)) {
            this.notificationConfiguration = new user_configuration_1.UserNotificationConfiguration(new user_configuration_1.UserNotificationConfiguration().getDefaults());
        }
    }
    getDefaults() {
        return {
            role: enums_1.RoleType.AircrewMember,
            specificationRoles: [],
            aircrewType: {
                type: enums_1.AircrewPositionType.External,
                dutyPositions: [],
            },
            squadronIds: [],
            invitedAt: 0,
            isPublicUser: false,
            mainSquadronId: 'unknown',
            isBiometricAuthEnabled: false,
            gradeRank: null,
        };
    }
    getSchema() {
        return user_schema_1.userSchema;
    }
}
exports.User = User;
