"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gradeRankSchema = void 0;
const yup = require("yup");
exports.gradeRankSchema = yup.object().shape({
    id: yup.string().notRequired(),
    squadronId: yup.string().required(),
    grade: yup.string().required(),
    rank: yup.string().required(),
});
