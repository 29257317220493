"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTimeStringInput = void 0;
function formatTimeStringInput(rawTimeString) {
    if (!rawTimeString) {
        return rawTimeString;
    }
    // Remove any non-digit characters
    const cleanTime = rawTimeString.replace(/\D/g, '');
    if (!cleanTime) {
        return rawTimeString;
    }
    // Handle single digit input
    if (cleanTime.length === 1) {
        return `00:0${cleanTime}`;
    }
    // Handle 2 digit input
    if (cleanTime.length === 2) {
        return `00:${cleanTime}`;
    }
    // Handle 3 digit input
    if (cleanTime.length === 3) {
        return `0${cleanTime[0]}:${cleanTime.slice(1)}`;
    }
    // Handle 4+ digit input - take last 4 digits
    const lastFour = cleanTime.slice(-4);
    return `${lastFour.slice(0, 2)}:${lastFour.slice(2)}`;
}
exports.formatTimeStringInput = formatTimeStringInput;
