"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dd791Schema = void 0;
const yup = require("yup");
exports.dd791Schema = yup.object().shape({
    id: yup.string().notRequired(),
    missionNo: yup.string().nullable(),
    tankerDoDAAC: yup.string().nullable(),
    missionDateAndTimeStartDate: yup.string().nullable(),
    missionDateAndTimeStartTime: yup.string().nullable(),
    missionDateAndTimeEndDate: yup.string().nullable(),
    missionDateAndTimeEndTime: yup.string().nullable(),
    tankerType: yup.string().nullable(),
    tankerNumber: yup.string().nullable(),
    fuelGrade: yup.string().nullable(),
    reFuelersNameAndGrade: yup.string().nullable(),
    quantityIssuedPoundsTotal: yup.string().nullable(),
    quantityIssuedGallonsTotal: yup.string().nullable(),
    issuesDataRows: yup.array().nullable(),
    eventId: yup.string().nullable(),
    isLocked: yup.boolean().required(),
});
