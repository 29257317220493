"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfficialFormType = void 0;
var OfficialFormType;
(function (OfficialFormType) {
    OfficialFormType["AFTO781"] = "afto781";
    OfficialFormType["AF1522"] = "af1522";
    OfficialFormType["AF3956"] = "af3956";
    OfficialFormType["AFTO781_METREA"] = "afto781Metrea";
    OfficialFormType["AFTO782_METREA"] = "afto782Metrea";
    OfficialFormType["IN_FLIGHT_ISSUE_LOG_METREA"] = "inFlightIssueLogMetrea";
    OfficialFormType["DD791"] = "dd791";
})(OfficialFormType = exports.OfficialFormType || (exports.OfficialFormType = {}));
