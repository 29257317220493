"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GradeRank = void 0;
const base_model_1 = require("../base-model");
const grade_rank_schema_1 = require("./grade-rank.schema");
class GradeRank extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
    }
    getDefaults() {
        return {};
    }
    getSchema() {
        return grade_rank_schema_1.gradeRankSchema;
    }
}
exports.GradeRank = GradeRank;
