"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GradeRankMock = void 0;
const grade_rank_model_1 = require("./grade-rank.model");
class GradeRankMock extends grade_rank_model_1.GradeRank {
    constructor(data) {
        const seedData = Object.assign({ id: '^&STEVC*SCU', squadronId: '123', grade: '1', rank: '1' }, data);
        super(seedData);
    }
}
exports.GradeRankMock = GradeRankMock;
