import { createAction, props } from '@ngrx/store';
import { AvailabilityDateRange, CalendarEvent, IEventDisplayConfig } from 'contracts';
import { MobileFooterActionType } from 'src/app/core/enums';

export const SetSidebarStatus = createAction('[Root] Set current sidebar status', props<{ isClosed: boolean }>());

export const SetCreateEventIdStatus = createAction('[Root] Set createEventId  status', props<{ isOpened: boolean }>());

export const ToggleSidebar = createAction('[Root] toggle sidebar status');

export const ToogleCreateEventId = createAction('[Root] toggle createEventId status');

export const ToggleNotifications = createAction('[Root] toggle notifications status');

export const ToggleAvailability = createAction('[Root] toggle availability status');

export const ToggleAdminAvailability = createAction('[Root] toggle admin availability status');

export const SetAvailabilityStatus = createAction('[Root] set availability status', props<{ isOpened: boolean }>());

export const SetAdminAvailabilityStatus = createAction(
  '[Root] set admin availability status',
  props<{ isOpened: boolean }>()
);

export const SetNotificationsDrawerStatus = createAction(
  '[Root] Set notifications drawer status',
  props<{ isOpened: boolean }>()
);

export const SetIsSmallScreen = createAction('[Root] Set is small screen', props<{ isSmall: boolean }>());

export const SetMobileMenuOptionSelected = createAction(
  '[Root] Set mobile menu option selected',
  props<{ mobileMenuOptionSelected: MobileFooterActionType }>()
);

export const RefreshApplicationContent = createAction('[Root] Refresh application content');

export const setIsBulkImportVisible = createAction(
  '[Root] Set bulk import modal visible',
  props<{ isVisible: boolean }>()
);

export const setUsersAvailabilities = createAction(
  '[Root] Set users availabilities',
  props<{ availabilities: AvailabilityDateRange[] }>()
);

export const deleteUserAvailability = createAction(
  '[Root] Delete user availability',
  props<{ availabilityId: string }>()
);

export const addUserAvailability = createAction(
  '[Root] Add user availability',
  props<{ availability: AvailabilityDateRange }>()
);

export const updateUserAvailability = createAction(
  '[Root] Add user availability',
  props<{ availability: AvailabilityDateRange }>()
);

// Auth user availability
export const setAuthUsersAvailabilities = createAction(
  '[Root] Set auth users availabilities',
  props<{ availabilities: AvailabilityDateRange[] }>()
);

export const setIsExportContactVisible = createAction(
  '[Root] Set export contact modal visible',
  props<{ isVisible: boolean }>()
);

export const setIsMayorVersionUpdateNeeded = createAction(
  '[Root] Set mobile mayor version update needed',
  props<{ isMayorVersion: boolean }>()
);

export const setIsMinorVersionUpdateNeeded = createAction(
  '[Root] Set mobile minor version update needed',
  props<{ isMinorVersion: boolean }>()
);

export const setIsMobileKeyboardOpen = createAction(
  '[Root] Set mobile keyboard open',
  props<{ isMobileKeyboardOpen: boolean }>()
);

export const setEventToRenderDialogComments = createAction(
  '[Root] Set event to render dialog comments',
  props<{ event: CalendarEvent }>()
);

export const setEventDisplayConfig = createAction(
  '[Root] Set event display config',
  props<{ config: IEventDisplayConfig }>()
);
