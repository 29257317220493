"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SquadronInformation = void 0;
const base_model_1 = require("../../base-model");
const information_schema_1 = require("./information.schema");
class SquadronInformation extends base_model_1.BaseModel {
    constructor(data, skipValidation) {
        super(data, skipValidation);
        this.receiverTypes = [];
    }
    getDefaults() {
        return {
            receiverTypes: [],
        };
    }
    getSchema() {
        return information_schema_1.squadronInformationSchema;
    }
}
exports.SquadronInformation = SquadronInformation;
